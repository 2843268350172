<template>
	<div class="section section--sm">
		<div class="container">
			<div class="section__wrap">
				<div class="section__cnt">
					<div class="section__head section__head--table">
						<div class="title title--section">Static pages template</div>

						<div class="section__options">
							<div class="section__option" v-if="!hasAdminTypeTypography">
								<button class="btn btn--sm" @click="onSave()">Create</button>
							</div>
						</div>
					</div>

					<div class="section__main">
						<v-table :headers="tabelHeaders"
								:items="list"
								:hasLoadList="loadingGetList"
								:pagination="listPagination"
								@changeLimit="onPaginationChangeLimit"
								@changePage="onPaginationChangePage">
							<tr v-for="(td, index) in list" :key="index">
								<td>{{td.id}}</td>
								<td>{{td.key}}</td>
								<td>{{td.title}}</td>
								<td>
									<div class="table__options">
										<div class="table__option" @click="onSave(td)">
											<v-icon name="edit"></v-icon>
										</div>

										<div class="table__option">
											<alert-modal v-if="!hasAdminTypeTypography" :class="{'has-disabled': loadingDeleteItem}" @success="onDelete(td.id)">
												<v-icon name="trash-alt"></v-icon>
											</alert-modal>
										</div>
									</div>
								</td>
							</tr>
						</v-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	import VTable from '@components/VTable';
	import PagesSave from './PagesSave';

	export default {
		name: 'pages-index',
		components:{
			VTable
		},
		data(){
			return {
				tabelHeaders: [
					{key:'id', text: 'ID'},
					{key:'alias', text: 'Key'},
					{key:'title', text: 'Page title'},
					{key:'nav', text: ''}
				]
			}
		},

		computed:{
			...mapState({
				list: state => state.pages.list,
				listPagination: state => state.pages.listPagination,
				listFilter: state => state.pages.listFilter,
				loadingGetList: state => state.pages.loadingGetList,
				loadingDeleteItem: state => state.pages.loadingDeleteItem,
			}),

			...mapGetters({
				listActiveFilter: 'pages/listActiveFilter',
				currentTypography: 'currentTypography',
				hasAdminTypeTypography: 'hasAdminTypeTypography'
			})
		},

		created(){
			this.getFilterDataFromUrl();
			this.getList();
		},

		methods:{
			...mapMutations({
				SET_FILTER_LIST: 'pages/SET_FILTER_LIST'
			}),
			...mapActions({
				GET_LIST: 'pages/GET_LIST',
				GET_ITEM: 'pages/GET_ITEM',
				DELETE_ITEM: 'pages/DELETE_ITEM',
			}),


			onSave(item){
				if (item) {
					this.GET_ITEM(item.id).then(resp => {
						this.openModalSave(resp.item);
					})

				} else {
					this.openModalSave();
				}
			},

			openModalSave(item){
				this.$modal.show(PagesSave,{
					item: item,
				}, $MODAL_OPTIONS.full);
			},

			onDelete(id){
				this.DELETE_ITEM(id).then(() => {
					if(this.list.length === 1)this.setFilterDataToUrl();
				});
			},

			getList: $fn.debounce(function () {
				this.GET_LIST();
				this.setFilterDataToUrl();
			}, 500),

			getFilterDataFromUrl(){
				let query = this.$route.query;

				$fn.forIn(query,(val, key) => {
					this.SET_FILTER_LIST({key: key, val: val});
				})
			},

			setFilterDataToUrl(){
				this.$router.replace({ name: this.$route.name, query: this.listActiveFilter }).catch(() => {});
			},

			onPaginationChangeLimit(limit){
				this.SET_FILTER_LIST({key: 'limit', val: limit});
				this.getList();
			},

			onPaginationChangePage(page){
				this.SET_FILTER_LIST({key: 'page', val: page});
				this.getList();
			}
		}
	};
</script>

<style scoped lang=scss>
	@media only screen and (max-width: $md)  {
		td:nth-of-type(1):before { content: "ID"; }
		td:nth-of-type(2):before { content: "Ключ"; }
		td:nth-of-type(3):before { content: "Ссылка на страницу"; }
	}
</style>
