<template>
	<div class="vmodal vmodal--ease">
		<button class="vmodal__close" @click="$emit('close')">
			<v-icon name="times"></v-icon>
		</button>

		<div class="vmodal__container">
			<div class="vmodal__main">
				<div class="form">
					<div class="form__head">
						<div class="form__title">{{hasEditType ? 'Edit' : 'Save'}} статическую страницу</div>
					</div>

					<div class="grid">
						<div class="grid__coll grid__coll--12">
							<div class="form__item">
								<form-input v-model="formData.key"
										:class="{'has-disabled': hasEditType}"
										name="key"
										@enter="onSave"
										rules="required"
										label="Ключ"></form-input>
							</div>
						</div>
					</div>

					<div class="lang">
						<div class="lang__tabs">
							<div class="lang__tab" v-for="lang in languages"
									:class="{'has-active': lang.code === selectedLang}"
									@click="selectedLang = lang.code">{{lang.code}}</div>
						</div>
						<div class="lang__cnt">
							<div class="lang__form" v-for="lang in languages" v-if="lang.code === selectedLang">
								<div class="grid">
									<div class="grid__coll grid__coll--12">
										<div class="form__item">
											<form-input v-model="formData.title[lang.code]"
													name="title"
													@enter="onSave"
													label="Заголовок страницы"></form-input>
										</div>
									</div>
									<div class="grid__coll grid__coll--12">
										<div class="form__item">
											<form-editor v-model="formData.description[lang.code]"
													name="description"
													@enter="onSave"
													label="Контент страницы"></form-editor>
										</div>
									</div>
									<div class="grid__coll grid__coll--12">
										<div class="form__item">
											<form-editor v-model="formData.html[lang.code]"
													name="html"
													@enter="onSave"
													label="SEO контент"></form-editor>
										</div>
									</div>
									<div class="grid__coll grid__coll--12">

										<div class="form__item">
											<form-input v-model="formData.meta_title[lang.code]"
													name="meta_title"
													@enter="onSave"
													label="SEO заголовок"></form-input>
										</div>
									</div>
									<div class="grid__coll grid__coll--12">
										<div class="form__item">
											<form-textarea v-model="formData.meta_description[lang.code]"
													name="meta_desciprion"
													@enter="onSave"
													label="SEO описание"></form-textarea>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="form__footer">
						<button class="btn"
								:class="{'has-loading': loadingSaveItem}"
								@click="onSave"><span>{{hasEditType ? 'Edit' : 'Create'}}</span></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState, mapGetters, mapMutations, mapActions, } from 'vuex';

	export default {
		name: 'pages-save',
		props: ['item'],
		data(){
			return{
				selectedLang : this.$store.state.selectedLang,
				formData:{
					key: null,
					title: {},
					subtitle: {},
					description: {},

					meta_title: {},
					meta_description: {},

					html: {},
				}
			}
		},

		computed:{
			...mapState({
				loadingSaveItem: state => state.pages.loadingSaveItem,
				languages: state => state.languages.list,
			}),

			hasEditType(){
				return !!this.item;
			}
		},

		created(){
			if(this.hasEditType) this.setFormData()
		},

		methods:{
			...mapActions({
				SAVE_ITEM: 'pages/SAVE_ITEM'
			}),

			onSave(){
				this.$validator.validate().then(result => {
					if (result) {
						let formData = $fn.cloneDeep(this.formData);

						this.SAVE_ITEM($fn.assign(formData,{
							id: this.hasEditType ? this.item.id : null,
							is_active: 1
						})).then((resp) =>{
							this.$emit('close');

						}).catch(err => {
							$fn.setValidateErrors(err, this.errors);
						})
					}
				});
			},

			setFormData(){
				$fn.assign(this.formData, this.item);
			}
		}
	};
</script>

<style scoped lang=scss>
	.grid{
		&__coll--6{
			width: 50%;
		}
	}
</style>
